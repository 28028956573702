import React, { useState, useMemo } from 'react';
import { Menu, Layout, Typography, Col, Row, Badge, Spin, Space } from 'antd';
import {
    DashboardOutlined,
    TeamOutlined,
    CodeOutlined,
    CarryOutOutlined,
    UserOutlined,
    ShopOutlined,
    SettingOutlined,
    FireOutlined,
    HomeOutlined,
    ProfileOutlined,
    FieldTimeOutlined,
    TransactionOutlined,
    FileProtectOutlined,
    ContainerOutlined,
} from '@ant-design/icons';
import SiderLogo from '../../assets/SiderLogo.png';
import siderProfile from '../../assets/siderProfile.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWindowSize } from '../../hooks/useWindowSize';
import UseProfile from '../../hooks/UseProfile';
import UseSuppiler from '../../hooks/UseSuppiler';
const { Sider } = Layout;
const { Text, Title, Link } = Typography;

const menuList = [
    {
        label: '首頁',
        key: '/Home',
        icon: <HomeOutlined />,
    },
    {
        label: '儀表板',
        key: '/Dashbaord',
        icon: <DashboardOutlined />,
    },
    {
        label: '快速打單',
        key: '/quick-orders',
        icon: <FieldTimeOutlined />,
    },
    {
        label: '客戶管理',
        key: '/customer',
        icon: <TeamOutlined />,
        children: [
            {
                label: '客戶列表',
                key: '/customer/CustomerList',
            },
            {
                label: '月結客戶結算',
                key: '/customer/MonthlyCusList',
            },
            {
                label: '一般瓦斯表客戶列表',
                key: '/customer/NormalDeviceList',
            },
            {
                label: '新增顧客',
                key: '/customer/NewCustomer',
            },
        ],
    },
    {
        label: '訂單管理',
        key: '/orders',
        icon: <CarryOutOutlined />,
        children: [
            {
                label: '訂單列表',
                key: '/orders/AllOders',
            },
            {
                label: '新增訂單',
                key: '/orders/NewOrders',
            },
            {
                label: '瓦斯殘氣通報列表',
                key: '/orders/AllLowGas',
            },
            {
                label: '收帳、收桶列表',
                key: '/orders/BillOfSaleWorkList',
            },
            {
                label: '瓦斯價格設定',
                key: '/orders/GasPrice',
            },
        ],
    },
    {
        label: '裝罝管理',
        key: '/device',
        icon: <CodeOutlined />,
        children: [
            {
                label: '所有裝置',
                key: '/device/AllDevice',
            },
        ],
    },
    {
        label: '鋼瓶管理',
        key: '/cylinder',
        icon: <FireOutlined />,
        children: [
            {
                label: '所有鋼瓶',
                key: '/cylinder/AllCylinder',
            },
            {
                label: '新增鋼瓶',
                key: '/cylinder/NewCylinder',
            },
            {
                label: '鋼瓶時價',
                key: '/cylinder/CylinderPrice',
            },
            {
                label: '處理鋼瓶場所',
                key: '/cylinder/CylinderSites',
            },
        ],
    },
    {
        label: '帳務管理',
        key: '/finance',
        icon: <TransactionOutlined />,
        children: [
            {
                label: '營業日報表',
                key: '/finance/OperatingReport',
            },
            {
                label: '營業月報表',
                key: '/finance/MonthOperatingReport',
            },
            {
                label: '配送員報表',
                key: '/finance/CourierReport',
            },
            {
                label: '雜支管理',
                key: '/finance/MixedExpense',
            },
        ],
    },
    {
        label: '工單管理',
        key: '/work_order',
        icon: <ProfileOutlined />,
        children: [
            {
                label: '所有工單',
                key: '/work_order/AllWorkOrder',
            },
            {
                label: '新增工單',
                key: '/work_order/NewWorkOrder',
            },
        ],
    },
    {
        label: '發票管理',
        key: '/invoice',
        icon: <ContainerOutlined />,
        children: [
            {
                label: '發票列表',
                key: '/invoice/InvoiceList',
            },
        ],
    },
    {
        label: '帳號管理',
        key: '/account',
        icon: <UserOutlined />,
        children: [
            {
                label: '全部帳號',
                key: '/account/AllAcc',
            },
            {
                label: '新增帳號',
                key: '/account/NewAcc',
            },
            {
                label: '群組列表',
                key: '/account/Group',
                children: [
                    {
                        label: '全部群組',
                        key: '/account/Group/GroupList',
                    },
                    {
                        label: '新增群組',
                        key: '/account/Group/NewGroup',
                    },
                ],
            },
            {
                label: '配送員列表',
                key: '/account/AllCourier',
            },
        ],
    },
    {
        label: '商品管理',
        key: '/commodity',
        icon: <ShopOutlined />,
        children: [
            {
                label: '所有商品',
                key: '/commodity/AllCommodity',
            },
            {
                label: '新增商品',
                key: '/commodity/NewCommodity',
            },
        ],
    },
    {
        label: '用戶安全申報',
        key: '/userSecurity',
        icon: <FileProtectOutlined />,
    },
    // {
    //   label: '廣告管理',
    //   key: '/adv',
    //   icon: <ShopOutlined />,
    // },
    {
        label: '系統設定',
        key: '/system',
        icon: <SettingOutlined />,
    },
]; // submenu keys of first level

const rootSubmenuKeys = menuList.map((item) => item.key);

const SiderMenu = () => {
    const Location = useLocation();
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const profileData = UseProfile().profile;
    const [widthSize, setWidthSize] = useState(false);
    const [openKeys, setOpenKeys] = useState(['/Dashbaord']);
    const [selectedKeys, setselectedKeys] = useState([Location.pathname]);
    let supplier = UseSuppiler().supplier;
    let key;
    const whd = (e) => {
        navigate(e.key);
        sessionStorage.removeItem('normalDeviceListParams');
    };
    useMemo(() => {
        key = [Location.pathname][0].split('/');
        if (key.length <= 2) {
            setselectedKeys(Location.pathname);
        } else if (key[1] === 'userSecurity') {
            setselectedKeys('/userSecurity');
        } else if (key[2] !== 'Group') {
            setselectedKeys(`/${key[1]}/${key[2]}`);
            setOpenKeys([key.slice(0, 2).join('/')]);
        } else {
            setselectedKeys(`/${key[1]}/${key[2]}/${key[3]}`);
            setOpenKeys([key.slice(0, 2).join('/'), key.slice(0, 3).join('/')]);
        }
    }, [Location.pathname]);
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    useMemo(() => {
        if (windowSize.innerWidth < 1200) {
            setWidthSize(true);
        } else {
            setWidthSize(false);
        }
    }, [windowSize]);

    return (
        <Sider
            width={200}
            style={{ backgroundColor: '#fff' }}
            collapsed={windowSize.innerWidth < 1200 ? true : false}
        >
            <Row style={{ height: '10vh', textAlign: 'center' }} align='middle'>
                <Col span={widthSize ? 24 : 10}>
                    <img style={{ width: '50%' }} src={SiderLogo} alt='SiderLogo'></img>
                </Col>
                {!widthSize && (
                    <Col span={14} key={1}>
                        <Title
                            style={{
                                fontSize: 18,
                                margin: 0,
                                textAlign: 'left',
                                color: '#1890ff',
                                fontWeight: 'bold',
                            }}
                        >
                            {supplier?.supplier_name}
                        </Title>
                    </Col>
                )}
            </Row>
            <div
                style={{
                    textAlign: 'center',
                    minHeight: widthSize ? 50 : 200,
                    fontSize: 15,
                    marginTop: 10,
                }}
            >
                {!widthSize && (
                    <div>
                        <img
                            style={{ width: '40%' }}
                            src={siderProfile}
                            alt='siderProfile'
                            key={1}
                        ></img>
                    </div>
                )}
                <div
                    style={{
                        marginTop: 15,
                        fontWeight: 'bold',
                        marginLeft: widthSize ? 10 : 20,
                    }}
                >
                    {profileData ? (
                        <Space key={1}>
                            <Link
                                strong
                                underline
                                onClick={() =>
                                    navigate(
                                        `/account/AllAcc/AccDetailed/${profileData?.user.user_id}`,
                                    )
                                }
                            >
                                {profileData.user?.user_name}
                            </Link>
                            <Badge status='success' />
                        </Space>
                    ) : (
                        <Spin key={2} />
                    )}
                </div>
                {!widthSize && (
                    <div style={{ marginTop: 10 }} key={1}>
                        <Text>主管</Text>
                    </div>
                )}
            </div>
            <Menu
                theme='light'
                mode='inline'
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                defaultselectedkeys={[Location.pathname]}
                items={menuList}
                onClick={whd}
                selectedKeys={selectedKeys}
            />
        </Sider>
    );
};

export default SiderMenu;
